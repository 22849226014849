define("discourse/plugins/discourse-saved-searches-extension/discourse/components/service-search-extension", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "I18n", "@ember/object", "@glimmer/tracking", "discourse/lib/url", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _service, _I18n, _object, _tracking, _url, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.searchTerm}}
    <div class="search-extension-notification">
      <DButton
        @class="search-extension-button"
        @action={{this.addToList}}
        @icon="bell"
        @title="button.title"
      >
        <span>
          {{this.completeText}}
        </span>
      </DButton>
      <DButton
        @action={{this.settings}}
        @icon="far-chart-bar"
        @class="btn search-extension-button-icon"
        @title="settings.title"
      ><span>{{i18n "button.extension_config_button"}}</span></DButton>
    </div>
  {{/if}}
  {{#if this.showSuccessMessage}}
    <div class="alert alert-success search-extension-succcess">
      {{i18n "success.message"}}
    </div>
  {{/if}}
  */
  {
    "id": "+MaBkuEd",
    "block": "[[[41,[30,0,[\"searchTerm\"]],[[[1,\"  \"],[10,0],[14,0,\"search-extension-notification\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@class\",\"@action\",\"@icon\",\"@title\"],[\"search-extension-button\",[30,0,[\"addToList\"]],\"bell\",\"button.title\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[1,[30,0,[\"completeText\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,2],null,[[\"@action\",\"@icon\",\"@class\",\"@title\"],[[30,0,[\"settings\"]],\"far-chart-bar\",\"btn search-extension-button-icon\",\"settings.title\"]],[[\"default\"],[[[[10,1],[12],[1,[28,[35,4],[\"button.extension_config_button\"],null]],[13]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showSuccessMessage\"]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-success search-extension-succcess\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[\"success.message\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"d-button\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-saved-searches-extension/discourse/components/service-search-extension.hbs",
    "isStrictMode": false
  });
  class ServiceSearchExtension extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "showSuccessMessage", [_tracking.tracked], function () {
      return false;
    }))();
    #showSuccessMessage = (() => (dt7948.i(this, "showSuccessMessage"), void 0))();
    get searchTerm() {
      return this.search.activeGlobalSearchTerm;
    }
    get completeText() {
      return _I18n.default.t("button.label", {
        term: this.searchTerm
      });
    }
    async addToList() {
      const path = "/add_parameter_to_list";
      try {
        await (0, _ajax.ajax)(path, {
          type: "PUT",
          data: {
            username: this.currentUser.username,
            search_term: this.searchTerm
          }
        });
        this.showSuccessMessage = true;
        setTimeout(() => {
          this.showSuccessMessage = false;
        }, 2000);
      } catch (error) {
        let errorMessage = error.jqXHR?.responseJSON?.errors?.[0] || "";
        let errorKey = errorMessage.split(": ").pop().trim();
        const errorMessages = {
          missing_username: "js.message_error.missing_username",
          missing_search_term: "js.message_error.missing_parameter",
          limit_reached: "js.message_error.limit_reached",
          not_found: "js.message_error.missing_username"
        };
        if (errorMessages[errorKey]) {
          const message = i18n(errorMessages[errorKey]);
          if (errorKey === "limit_reached") {
            const buttons = [{
              label: "OK",
              class: "btn btn-default"
            }, {
              label: i18n("js.message_error.limit_reached_button"),
              class: "btn btn-primary",
              action: () => {
                _url.default.routeTo(`/my/preferences/saved-searches`);
              }
            }];
            this.dialog.dialog({
              message,
              buttons,
              type: "confirm"
            });
          } else {
            this.dialog.alert(message);
          }
        } else {
          (0, _ajaxError.popupAjaxError)(error);
        }
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "addToList", [_object.action]))();
    settings() {
      _url.default.routeTo(`/my/preferences/saved-searches`);
    }
    static #_6 = (() => dt7948.n(this.prototype, "settings", [_object.action]))();
  }
  _exports.default = ServiceSearchExtension;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ServiceSearchExtension);
});